import H1 from 'primitives/Typography/Headings/H1';
import P from 'primitives/Typography/BodyCopy/P';
import PageWrapper from 'primitives/Layout/PageWrapper';
import { IGetTranslateFunctionResponse } from 'redux-i18n';

type Props = {
  translate: IGetTranslateFunctionResponse;
};

function FamilyPlanChildSection({ translate }: Props) {
  const bodyCopy = "You're all set to start enjoying iHeart All Access.";
  return (
    <PageWrapper>
      <H1>{translate('Success!')}</H1>
      <P>{bodyCopy}</P>
    </PageWrapper>
  );
}

export default FamilyPlanChildSection;
