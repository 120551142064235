import styled from '@emotion/styled';
import type { Colors } from 'styles/colors';

type Props = {
  textColor?: (color: Colors) => string;
};

const Label = styled('label')<Props>(({ textColor, theme }) => ({
  color: theme.colors.black.secondary,
  display: 'inline-block',
  fontSize: '1.3rem',
  letterSpacing: '0.2rem',
  lineHeight: '1.38',
  margin: '2rem 0 1rem',
  textColor: textColor && textColor(theme.colors),
  textTransform: 'uppercase',
}));

export default Label;
