import FilledButton from 'primitives/Buttons/FilledButton';
import H1 from 'primitives/Typography/Headings/H1';
import Input from 'primitives/Forms/Input';
import Label from 'primitives/Forms/Label';
import P from 'primitives/Typography/BodyCopy/P';
import PageWrapper from 'primitives/Layout/PageWrapper';
import { IGetTranslateFunctionResponse } from 'redux-i18n';
import { PureComponent } from 'react';

type Props = {
  submitForm: (childZipCode: HTMLInputElement | null | undefined) => void;
  translate: IGetTranslateFunctionResponse;
};

class FamilyPlanChildValidationSection extends PureComponent<Props> {
  childZipCode: HTMLInputElement | null | undefined;

  render() {
    const { submitForm, translate } = this.props;
    return (
      <PageWrapper>
        <H1>
          {translate(
            'Almost there! Verify your zipcode to unlock your spot on the Family Plan',
          )}
        </H1>
        <P color={colors => colors.gray.dark!}>
          {translate(
            'In order to add you to the plan, we need to make sure that you live at the same address as the plan owner. Please input your zipcode below to verify and unlock your account.',
          )}
        </P>

        <Label textColor={colors => colors.gray.dark!}>
          {translate('Household Zipcode')}
        </Label>
        <Input
          placeholder={translate('e.g 90210...')}
          ref={childZipCode => {
            this.childZipCode = childZipCode;
          }}
          width="100%"
        />
        <FilledButton
          marginTop="1.6rem"
          onClick={() => submitForm(this.childZipCode)}
          style={{ float: 'right' }}
          styleType="cta"
        >
          {translate('Submit')}
        </FilledButton>
      </PageWrapper>
    );
  }
}

export default FamilyPlanChildValidationSection;
