import H1 from 'primitives/Typography/Headings/H1';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const MessagingBlock = styled('div')(({ theme }) => {
  return {
    padding: '10rem 15rem',
    maxWidth: '80vw',
    margin: '0 auto',
    [mediaQueryBuilder(theme.mediaQueries.max.width['1024'])]: {
      [H1.toString()]: {
        fontSize: '3rem',
        lineHeight: '3.3rem',
      },
    },
    [mediaQueryBuilder(theme.mediaQueries.max.width['899'])]: {
      [H1.toString()]: {
        fontSize: '2.5rem',
        lineHeight: '2.75rem',
      },
    },
    [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
      padding: '10rem 5rem',
    },
  };
});

export default MessagingBlock;
