import BR from 'primitives/Layout/BR';
import H2 from 'primitives/Typography/Headings/H2';
import InlineLink from 'primitives/Typography/Links/InlineLink';
import P from 'primitives/Typography/BodyCopy/P';
import PageWrapper from 'primitives/Layout/PageWrapper';
import { IGetTranslateFunctionResponse } from 'redux-i18n';

type Props = {
  translate: IGetTranslateFunctionResponse;
};

function NonEligableSection({ translate }: Props) {
  const helplink = (
    <InlineLink
      href="https://ihr.fm/cancel"
      rel="noopener noreferrer"
      target="_blank"
    >
      {translate('help site')}
    </InlineLink>
  );
  const supportLink = (
    <a href="mailto:support@iheartradio.com?Subject=Family%20Plan%20Validation">
      support@iheartradio.com
    </a>
  );

  return (
    <PageWrapper>
      <H2>{translate("It looks like you're already subscribed")}</H2>
      <BR dimension={dimensions => dimensions.pageSpacerMedium} />
      <P color={colors => colors.gray.medium!}>
        {translate(
          "You must cancel your active subscription before you can continue. You can find out how to cancel your subscription at {supportLink}. Once you've cancelled your current subscription plan you will need to wait until your current subscription plan ends.",
          { supportLink },
        )}
      </P>
      <BR dimension={dimensions => dimensions.pageSpacerMedium} />
      <P>
        {translate('If you have any questions, please {helplink} contact us.', {
          helplink,
        })}
      </P>
    </PageWrapper>
  );
}

export default NonEligableSection;
